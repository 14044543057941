<template>
  <v-card class="mx-auto">
    <v-dialog v-model="metadialog">
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit Sequence</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="editedMeta.composer"
                  label="Composer"
                ></v-text-field>
                <v-text-field
                  v-model="editedMeta.name"
                  label="Name"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeMeta"> Cancel </v-btn>
          <v-btn text @click="saveMeta"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="stepdialog" @keydown.enter="saveStep()">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >Edit Step <actionformatter large v-model="editedStep.item"
          /></span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6" sm="12" md="6">
                <v-text-field
                  v-model="editedStep.item.label"
                  label="Label"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="12" md="6">
                <v-select
                  v-model="editedStep.item.division"
                  :items="['General', 'Great', 'Swell', 'Choir', 'Pedal']"
                  label="Division"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-if="
                    !editedStep.item.parsed &&
                    typeof editedStep.item.args == 'string'
                  "
                  v-model="editedStep.item.args"
                  label="Text"
                ></v-text-field>
                <name-valuelist
                  v-if="
                    !editedStep.item.parsed &&
                    typeof editedStep.item.args == 'object'
                  "
                  :nvs="editedStep.item.args"
                  v-model="editedStep.nv"
                />
                <stoplist
                  v-if="editedStep.item.parsed"
                  :stops="stoplist ? Object.keys(stoplist) : []"
                  v-model="editedStep.stops"
                  compact
                ></stoplist>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeStep"> Cancel </v-btn>
          <v-btn text @click="saveStep"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-title>
      <span @click="editmode ? editMeta() : $router.replace('library')"
        >{{ sequence.composer }} - {{ sequence.name }} - {{ curlabel }}</span
      >
      <v-spacer></v-spacer>
      <span v-show="editmode"
        >{{ sequence.historytime
        }}<v-btn icon
          ><v-icon
            @keyup.ctrl.z="doundo()"
            :disabled="!state.canundo"
            @click="doundo()"
            >mdi-undo</v-icon
          ></v-btn
        ><v-btn icon
          ><v-icon :disabled="!state.canredo" @click="doAction('sequence.redo')"
            >mdi-redo</v-icon
          ></v-btn
        ></span
      >
      <v-btn icon @click="compact = !compact"
        ><v-icon v-if="compact">mdi-unfold-more-vertical</v-icon
        ><v-icon v-if="!compact">mdi-unfold-less-vertical</v-icon></v-btn
      >
      <v-btn icon>
        <v-icon v-on:keyup.F2="toggleEdit" @click="toggleEdit()">{{
          editmode ? "mdi-lock" : "mdi-pencil"
        }}</v-icon>
      </v-btn>
    </v-card-title>

    <v-data-table
      fixed-header
      :headers="headers"
      :items="steps"
      :items-per-page="-1"
      :search="search"
      hide-default-footer
      :footer-props="{ disablePagination: true, disableItemsPerPage: true }"
      :dense="$vuetify.breakpoint.mdAndUp"
      mobile-breakpoint="0"
    >
      <template v-slot:item="{ item }">
        <tr
          :key="item.id"
          :class="isActive(item.id) ? 'primary white--text' : ''"
          :id="isActive(item.id) ? 'ActiveStep' : ''"
        >
          <td @click="editmode ? selectStep(item) : goStep(item)">
            {{ item.division ? item.division + "." + item.label : item.label }}
          </td>
          <td
            align="right"
            @click="this.editmode ? editStep(item) : goStep(item)"
          >
            <actionformatter :item="item" />
          </td>
          <td @click.prevent="editmode ? editStep(item) : goStep(item)">
            <div v-if="!item.parsed && typeof item.args == 'string'">
              <b v-html="item.args"></b>
            </div>

            <div v-if="!item.parsed && typeof item.args == 'object'">
              <namevaluelist :nvs="item.args" />
            </div>

            <div v-if="item.parsed">
              <span
                v-if="
                  compact && item.parsed.delta.length < item.parsed.stops.length
                "
                ><v-icon size="medium">mdi-plus-box-outline</v-icon>&nbsp;
                <stoplist :stops="item.parsed.delta" outlined disabled
              /></span>
              <stoplist v-else :stops="item.parsed.stops" outlined disabled />
            </div>
          </td>
          <td align="right">
            <v-btn
              icon
              v-if="editmode && item.id == steps.length - 1"
              @click="stepAction('sequence.stepcopy', item)"
              ><v-icon>mdi-plus-circle</v-icon></v-btn
            >

            <v-icon
              color="red"
              v-if="poweredon && state.recording != 'off'"
              @click="writeStep(item)"
              >mdi-record-rec</v-icon
            >
            <v-menu v-if="editmode" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="newStep(item, 'stop.set', [])">
                  <v-list-item-title
                    ><v-icon>mdi-plus-box</v-icon>New
                    <action-formatter action="stop.set"
                  /></v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="newStep(item, 'sequence.comment', 'comment')"
                >
                  <v-list-item-title
                    ><v-icon>mdi-plus-box</v-icon>New
                    <action-formatter action="sequence.comment"
                  /></v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="
                    newStep(item, 'piston.define', {
                      id: 0,
                      action: 'stop.off',
                      args: 'all',
                    })
                  "
                >
                  <v-list-item-title
                    ><v-icon>mdi-plus-box</v-icon>New
                    <action-formatter action="piston.define"
                  /></v-list-item-title>
                </v-list-item>

                <v-list-item @click="editStep(item)">
                  <v-list-item-title
                    ><v-icon>mdi-pencil</v-icon>Edit</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="stepAction('sequence.stepcopy', item)">
                  <v-list-item-title
                    ><v-icon>mdi-content-copy</v-icon
                    >Duplicate</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="stepAction('sequence.stepdelete', item)">
                  <v-list-item-title
                    ><v-icon>mdi-delete</v-icon>Delete</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="stepAction('sequence.stepup', item)">
                  <v-list-item-title
                    ><v-icon>mdi-arrow-up-bold-box-outline</v-icon>Move
                    Up</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="stepAction('sequence.stepdown', item)">
                  <v-list-item-title
                    ><v-icon>mdi-arrow-down-bold-box-outline</v-icon>Move
                    Down</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ActionFormatter from "../components/ActionFormatter.vue";
import NameValuelist from "../components/NameValuelist.vue";

export default {
  components: { NameValuelist, ActionFormatter },
  data: () => ({
    editmode: false,
    compact: true,

    metadialog: false,
    stepdialog: false,

    search: "",

    headers: [
      { text: "Label", value: "label", width: "1%", sortable: false },
      { text: "Action", value: "action", width: "1%", sortable: false },
      { text: "Parameters", value: "args", sortable: false },
      { text: "", value: "edit", sortable: false },
    ],
    
    editedMeta: {
      name: "",
      composer: "",
    },

    editedStep: {
      item: {
        label: "",
      },
      nv: Object.assign({}, {}),
      stops: [123],
    },
  }),
  computed: {
    ...mapGetters("specification", [
      "divisions",
      "stoplist",
      "divstoplist",
      "poweredon",
    ]),
    ...mapGetters("sequence", [
      "sequence",
      "state",
      "curlabel",
      "curposition",
    ]),

    steps() {
      if (this.sequence && this.sequence.steps) {
        return this.sequence.steps;
      } else return [];
    },
  },
  watch: {
    curposition(curstep) {
      if (curstep >= 0)
        this.$nextTick(() =>
          this.$vuetify.goTo("#ActiveStep", { offset: 100 })
        );
    },
  },

  methods: {
    toggleEdit() {
      this.editmode = !this.editmode;
    },
    doundo(event) {
      console.log("doundo" + event);
      return this.doAction("sequence.undo");
    },
    isActive(pos) {
      return this.curposition == pos;
    },
    doAction: function (a) {
      this.$socket.emit("action", { cmd: a });
    },
    stepAction: function (a, v) {
      this.$socket.emit("action", { cmd: a, item: v });
    },

    goStep: function (v) {
      this.$socket.emit("action", { cmd: "sequence.go", pos: v.id });
    },
    writeStep: function (v) {
      this.$socket.emit("action", { cmd: "sequence.steprecord", pos: v.id });
    },
    selectStep: function (v) {
      this.$socket.emit("action", { cmd: "sequence.go", select: v.id });
    },

    editStep(item) {
      this.selectStep(item);
      // a lot of mess here because of the components used. These initialize pointers to the modifiable objects once
      // so we want to make sure the objects to modified are getting pointed at.
      // however then vue might not notice changes in the object, for that we use $set

      // seems to be best way to deep copy object
      this.editedStep.item = JSON.parse(JSON.stringify(item));

      if (typeof item.args == "object") {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.editedStep.nv)) {
          this.$delete(this.editedStep.nv, key);
        }

        for (const [key, value] of Object.entries(item.args)) {
          this.$set(this.editedStep.nv, key, value);
        }
      }

      this.editedStep.stops.length = 0;

      if (item.parsed) {
        // keep same address to support v-model. Must be a more elegant way ??
        this.editedStep.stops.push(...item.parsed.stops);
      }

      this.stepdialog = true;
    },
    closeStep() {
      this.stepdialog = false;
    },
    saveStep() {
      if (this.editedStep.item.parsed)
        this.editedStep.item.parsed.stops = [...this.editedStep.stops];

      if (typeof this.editedStep.item.args == "object")
        this.editedStep.item.args = this.editedStep.nv;

      this.stepAction("sequence.stepedit", this.editedStep.item);
      this.closeStep();
    },

    editMeta() {
      this.editedMeta.name = this.sequence.name;
      this.editedMeta.composer = this.sequence.composer;
      this.metadialog = true;
    },
    closeMeta() {
      this.metadialog = false;
    },
    saveMeta() {
      this.$socket.emit("action", {
        cmd: "sequence.metaedit",
        args: this.editedMeta,
      });
      this.closeMeta();
    },
    newStep: function (i, t, a) {
      console.log("newStep " + t);
      this.$socket.emit("action", {
        cmd: "sequence.stepadd",
        item: i,
        type: t,
        args: a,
      });
    },
  },
  mounted() {
    let self = this;

    if (self.hasevent) return;
    self.hasevent = true; //install it only oncuechange...

    window.addEventListener("keyup", function (event) {
      if (event.key === "F2") {
        self.toggleEdit();
      } else if (event.key === "z" && event.ctrlKey) {
        self.doAction("sequence.undo");
      } else if (event.key === "y" && event.ctrlKey) {
        self.doAction("sequence.redo");
      }
    });
  },
};
</script>
