import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        
      },
      dark: {
        primary: colors.grey.darken2,
        background: colors.grey.base,
        highlight: colors.green,
      },
    },
  },
});
