<template>
  <v-card
    ><v-card-text>
      <v-card-title>For easy use of the setter, use the buttons below.</v-card-title>
         <v-card-subtitle>Press <v-icon>mdi-menu</v-icon> then <v-icon>mdi-head-flash-outline</v-icon> Start to return to this screen</v-card-subtitle>
      <v-btn rounded x-large block class="mb-4" to="/library"
        >Select sequence and play</v-btn
      >
      <v-btn rounded x-large block class="mb-4">Create new sequence</v-btn>

      <v-btn rounded x-large block class="mb-4" to="/info"
        >How to use this setter?</v-btn
      >
      
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  computed: {},
  methods: {},
  mounted() {},
};
</script>
