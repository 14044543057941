import router from "../../router";

// initial state
const state = () => ({
  library: [],
  autonav: "",
});

// getters
const getters = {
  library: (state) => {
    return state.library;
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  state(state, lib) {
    lib.items.forEach((element, index) => {
      element.id = index;
    });
    state.library = lib;
    if (state.autonav != "" && router.currentRoute.fullPath != state.autonav) {
      router.push(state.autonav);
    }

    state.autonav = "";
  },
  autonav(state, location) {
    state.autonav = location;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
