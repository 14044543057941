<template>
  <v-card class="mx-auto">
    <v-dialog v-model="metadialog">
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit Combination Set</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="editedMeta.composer"
                  label="Composer"
                ></v-text-field>
                <v-text-field
                  v-model="editedMeta.name"
                  label="Name"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeMeta"> Cancel </v-btn>
          <v-btn text @click="saveMeta"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="combDialog" @keydown.enter="saveComb()">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >Edit Comb <actionformatter large v-model="editedComb.item"
          /></span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6" sm="12" md="6">
                <v-text-field
                  v-model="editedComb.item.label"
                  label="Label"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="12" md="6">
                <v-select
                  v-model="editedComb.item.division"
                  :items="['General', 'Great', 'Swell', 'Choir', 'Pedal']"
                  label="Division"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-if="
                    !editedComb.item.parsed &&
                    typeof editedComb.item.args == 'string'
                  "
                  v-model="editedComb.item.args"
                  label="Text"
                ></v-text-field>
                <name-valuelist
                  v-if="
                    !editedComb.item.parsed &&
                    typeof editedComb.item.args == 'object'
                  "
                  :nvs="editedComb.item.args"
                  v-model="editedComb.nv"
                />
                <stoplist
                  v-if="editedComb.item.parsed"
                  :stops="stoplist ? Object.keys(stoplist) : []"
                  v-model="editedComb.stops"
                  compact
                ></stoplist>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeComb"> Cancel </v-btn>
          <v-btn text @click="saveComb"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-title>
      <span @click="editmode ? editMeta() : $router.replace('library')"
        >{{ combination.composer }} - {{ combination.name }} -
        {{ curlabel }}</span
      >
      <v-spacer></v-spacer>
      <span v-show="editmode"
        >{{ combination.historytime
        }}<v-btn icon
          ><v-icon
            @keyup.ctrl.z="doundo"
            :disabled="!state.canundo"
            @click="combAction('combination.undo')"
            >mdi-undo</v-icon
          ></v-btn
        ><v-btn icon
          ><v-icon
            :disabled="!state.canredo"
            @click="combAction('combination.redo')"
            >mdi-redo</v-icon
          ></v-btn
        ></span
      >
      <v-btn icon @click="compact = !compact"
        ><v-icon v-if="compact">mdi-unfold-more-vertical</v-icon
        ><v-icon v-if="!compact">mdi-unfold-less-vertical</v-icon></v-btn
      >
      <v-btn icon>
        <v-icon v-on:keyup.F2="toggleEdit" @click="toggleEdit()">{{
          editmode ? "mdi-lock" : "mdi-pencil"
        }}</v-icon>
      </v-btn>
    </v-card-title>

    <v-data-table
      fixed-header
      :headers="headers"
      :items="combinations"
      :items-per-page="-1"
      :search="search"
      hide-default-footer
      :footer-props="{ disablePagination: true, disableItemsPerPage: true }"
      :dense="$vuetify.breakpoint.mdAndUp"
      mobile-breakpoint="0"
    >
      <template v-slot:item="{ item }">
        <tr
          :key="item.id"
          :class="isActive(item.id) ? 'primary white--text' : ''"
          :id="isActive(item.id) ? 'ActiveComb' : ''"
        >
          <td @click="this.editmode ? editComb(item) : goComb(item)">
            {{ item.division }}
          </td>
          <td align="right" @click="editmode ? selectComb(item) : goComb(item)">
            <v-chip outlined>{{ item.label }}</v-chip>
          </td>
          <td @click.prevent="editmode ? editComb(item) : goComb(item)">
            <div v-if="!item.parsed && typeof item.args == 'string'">
              <b v-html="item.args"></b>
            </div>

            <div v-if="!item.parsed && typeof item.args == 'object'">
              <namevaluelist :nvs="item.args" />
            </div>

            <div v-if="item.parsed">
              <span
                v-if="
                  compact && item.parsed.delta.length < item.parsed.stops.length
                "
                ><v-icon size="medium">mdi-plus-box-outline</v-icon>&nbsp;
                <stoplist :stops="item.parsed.delta" outlined disabled
              /></span>
              <stoplist v-else :stops="item.parsed.stops" outlined disabled />
            </div>
          </td>
          <td align="right">
            <v-icon
              color="red"
              v-if="poweredon && editmode"
              @click="writeComb(item)"
              >mdi-record-rec</v-icon
            >
            <v-menu v-if="editmode" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="newComb(item, 'stop.set', [])">
                  <v-list-item-title
                    ><v-icon>mdi-plus-box</v-icon>New
                    <action-formatter action="stop.set"
                  /></v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="newComb(item, 'combination.comment', 'comment')"
                >
                  <v-list-item-title
                    ><v-icon>mdi-plus-box</v-icon>New
                    <action-formatter action="combination.comment"
                  /></v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="
                    newComb(item, 'piston.define', {
                      id: 0,
                      action: 'stop.off',
                      args: 'all',
                    })
                  "
                >
                  <v-list-item-title
                    ><v-icon>mdi-plus-box</v-icon>New
                    <action-formatter action="piston.define"
                  /></v-list-item-title>
                </v-list-item>

                <v-list-item @click="editComb(item)">
                  <v-list-item-title
                    ><v-icon>mdi-pencil</v-icon>Edit</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="combAction('combination.copy', item)">
                  <v-list-item-title
                    ><v-icon>mdi-content-copy</v-icon
                    >Duplicate</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="combAction('combination.delete', item)">
                  <v-list-item-title
                    ><v-icon>mdi-delete</v-icon>Delete</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ActionFormatter from "../components/ActionFormatter.vue";
import NameValuelist from "../components/NameValuelist.vue";

export default {
  components: { NameValuelist, ActionFormatter },
  data: () => ({
    editmode: false,
    compact: false,

    metadialog: false,
    combDialog: false,

    search: "",

    headers: [
      { text: "Division", value: "action", width: "1%", sortable: false },
      { text: "Piston", value: "label", width: "1%", sortable: false },
      { text: "Stops", value: "args", sortable: false },
      { text: "", value: "edit", sortable: false },
    ],

    editedMeta: {
      name: "",
      composer: "",
      library: "",
    },

    editedComb: {
      item: {
        label: "",
      },
      nv: Object.assign({}, {}),
      stops: [123],
    },
  }),
  computed: {
    ...mapGetters("specification", [
      "divisions",
      "stoplist",
      "divstoplist",
      "poweredon",
    ]),
    ...mapGetters("library", ["library"]),
    ...mapGetters("combination", [
      "combination",
      "state",
      "curlabel",
      "curpositions",
    ]),

    combinations() {
      if (this.combination && this.combination.combinations) {
        return this.combination.combinations;
      } else return [];
    },
  },

  methods: {
    toggleEdit() {
      this.editmode = !this.editmode;
    },
    doundo(event) {
      return this.combAction("combination.undo");
    },
    isActive(pos) {
      if (this.curpositions)
        return Object.values(this.curpositions).includes(pos);
      else return false;
    },
    combAction: function (a, v = null) {
      if (v) this.$socket.emit("action", { cmd: a, item: v });
      else this.$socket.emit("action", { cmd: a });
    },
    goComb: function (v) {
      this.$socket.emit("action", { cmd: "combination.go", pos: v.id });
    },
    writeComb: function (v) {
      this.$socket.emit("action", { cmd: "combination.store", pos: v.id });
    },
    selectComb: function (v) {
      this.$socket.emit("action", { cmd: "combination.go", select: v.id });
    },
    editComb(item) {
      this.selectComb(item);
      // a lot of mess here because of the components used. These initialize pointers to the modifiable objects once
      // so we want to make sure the objects to modified are getting pointed at.
      // however then vue might not notice changes in the object, for that we use $set

      // seems to be best way to deep copy object
      this.editedComb.item = JSON.parse(JSON.stringify(item));

      if (typeof item.args == "object") {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.editedComb.nv)) {
          this.$delete(this.editedComb.nv, key);
        }

        for (const [key, value] of Object.entries(item.args)) {
          this.$set(this.editedComb.nv, key, value);
        }
      }

      this.editedComb.stops.length = 0;

      if (item.parsed) {
        // keep same address to support v-model. Must be a more elegant way ??
        this.editedComb.stops.push(...item.parsed.stops);
      }

      this.combDialog = true;
    },
    closeComb() {
      this.combDialog = false;
    },
    saveComb() {
      if (this.editedComb.item.parsed)
        this.editedComb.item.parsed.stops = [...this.editedComb.stops];

      if (typeof this.editedComb.item.args == "object")
        this.editedComb.item.args = this.editedComb.nv;

      this.combAction("combination.edit", this.editedComb.item);
      this.closeComb();
    },

    editMeta() {
      this.editedMeta.name = this.combination.name;
      this.editedMeta.composer = this.combination.composer;
      this.editedMeta.library = this.combination.library;
      this.metadialog = true;
    },
    closeMeta() {
      this.metadialog = false;
    },
    saveMeta() {
      this.$socket.emit("action", {
        cmd: "combination.metaedit",
        args: this.editedMeta,
      });
      this.closeMeta();
    },
    newComb: function (i, t, a) {
      this.$socket.emit("action", {
        cmd: "combination.stepadd",
        item: i,
        type: t,
        args: a,
      });
    },
  },
  mounted() {
    let self = this;

    if (self.hasevent) return;
    self.hasevent = true; //install it only oncuechange...

    window.addEventListener("keyup", function (event) {
      if (event.key === "F2") {
        self.toggleEdit();
      } else if (event.key === "z" && event.ctrlKey) {
        self.combAction("combination.undo");
      } else if (event.key === "y" && event.ctrlKey) {
        self.combAction("combination.redo");
      }
    });
  },
};
</script>
