import router from "../../router";

// initial state
const state = () => ({
  curstep: 0,
  sequence: [],
  autonav: "",
});

// getters
const getters = {
  curposition: (state) => {
    if (
      state.sequence &&
      state.sequence.state
    )
      return state.sequence.state.position;
    else return -1;
  },
  curstep: (state) => {
    if (
      state.sequence &&
      state.sequence.steps &&
      state.sequence.state.position >= 0 &&
      state.sequence.state.position <= state.sequence.steps.length
    )
      return state.sequence.steps[state.sequence.state.position];
    return null;
  },
  curlabel: (state) => {
    
    if (state.sequence && state.sequence.state) {
      
      return state.sequence.state.label
    }
    return "";
  },
  sequence: (state) => {
    return state.sequence ? state.sequence : [];
  },
  state: (state) => {
    return state.sequence && state.sequence.state
      ? state.sequence.state
      : {
          recording: "off",
          canredo: false,
          canundo: false,
          position: -1,
          percent: 50,
        };
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  state(state, sequence) {
    state.sequence = sequence;
    if (state.sequence && state.sequence.steps) {
      state.sequence.steps.forEach((element, index) => {
        element.id = index;
        if (!element.label) element.label = "";
      });
      // if (state.sequence.state.position < 0) state.sequence.state.position = 0;
      if (state.sequence.state.position >= state.sequence.steps.length)
        state.sequence.state.position = state.sequence.steps.length - 1;

      if (
        state.autonav != "" &&
        router.currentRoute.fullPath != state.autonav
      ) {
        router.push(state.autonav);
      }

      state.autonav = "";
    }
  },
  autonav(state, value) {
    state.autonav = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
