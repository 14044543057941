<template>
  <span>
    <span v-if="pistons" class="mr-1">
      <v-chip
        v-for="piston in pistons"
        :key="piston"
        outlined
        @click="pushPiston(piston)"
      >
        <b>{{ pistonName(piston) }}</b>
        
      </v-chip>
    </span>
  </span>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      stopstate: this.value,
    };
  },
  props: {
    pistons: { type: Array, required: true },
    outlined: { type: Boolean, default: false },
    value: { type: Array, default: null },
  },
  computed: {
    ...mapGetters("stopstate", ["stopStates"]),
    ...mapGetters("specification", ["divisions", "stoplist", "divstoplist"]),
  },
  methods: {
    pistonName(v) {
      void v;
      return "1";
    },
    pushPiston: function (v) {
      if (this.value) {
        v = parseInt(v);
        let pos = this.stopstate.indexOf(v);
        if (pos < 0) this.stopstate.push(v);
        else this.stopstate.splice(pos, 1);
        console.log(this.stopstate);
        this.$emit("input", this.stopstate);
      } else {
        this.$socket.emit("toggle", v);
      }
    },
  },
};
</script>
