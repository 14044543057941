<template>
  <v-card class="mx-auto overflow-hidden">
    <v-app-bar app large clipped-left>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="grey--text">
        {{ active ? active.name + " - " : "" }} {{ specification.name }} ({{
          specification.short
        }})
      </v-toolbar-title>
      <v-spacer />
      <span @click="pistbar = !pistbar">
        <v-icon>mdi-page-layout-footer</v-icon>
      </span>
      <span @click="stopwatch = !stopwatch">
        <v-icon v-show="!stopwatch">mdi-timer-outline</v-icon
        ><span v-show="stopwatch" class="mr-2">{{ stopwatchstr }}</span>
      </span>
      <span @click="clock = !clock">
        <v-icon v-show="!clock">mdi-clock-outline</v-icon
        ><span v-show="clock">{{ timestr }}</span>
      </span>
      <span @click="toggleDarkMode">
        <v-icon v-if="this.darkmode">mdi-white-balance-sunny</v-icon>
        <v-icon v-else>mdi-moon-waxing-crescent</v-icon>
      </span>

      <v-icon v-if="!this.$socket.connected">mdi-cloud-off-outline</v-icon>
    </v-app-bar>

    <v-navigation-drawer clipped app class="primary" v-model="drawer">
      <v-list>
        <v-list-item
          v-for="link in availableLinks"
          :key="link.route"
          router
          :to="link.route"
        >
          <v-list-item-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  setup() {},
  data() {
    return {
      drawer: false,
      clock: false,
      darkmode: false,
      stopwatch: false,
      timestr: "21:22:33",
      stopwatchstr: "00:00:05",
      invp: null,
      links: [
        {
          icon: "mdi-animation-play",
          text: "Performance",
          route: "/performance",
        },
        {
          icon: "mdi-head-flash-outline",
          text: "Start",
          route: "/guided",
        },
        {
          icon: "mdi-music-box-multiple-outline",
          text: "Library",
          route: "/library",
        },
        {
          icon: "mdi-dots-horizontal",
          text: "Combinations",
          route: "/combination",
        },
        {
          icon: "mdi-playlist-play",
          text: "Sequencer",
          route: "/sequence",
        },
        {
          icon: "mdi-dip-switch",
          text: "Stops",
          route: "/stops",
        },
        {
          icon: "mdi-arrow-top-right-bold-box",
          text: "Crescendo",
          route: "/crescendo",
        },

        { icon: "mdi-cog", text: "Configure", route: "/configure" },
        { icon: "mdi-account", text: "User", route: "/user" },
        { icon: "mdi-information", text: "Information", route: "/info" },
      ],
    };
  },
  computed: {
    ...mapGetters("specification", ["specification", "poweredon"]),
    ...mapGetters("users", ["users", "active"]),
    availableLinks() {
      return this.links.filter((e) => {
        if (
          e.route == "/stops" ||
          e.route == "/performance" ||
          e.route == "/crescendo"
        )
          return this.poweredon;
        else if (e.route == "/users") return this.users.length > 0;
        else return true;
      });
    },
    pistbar: {
      // apparently ...mapState does not work with v-model
      get() {
        return this.$store.state.users.showPistbar;
      },
      set(v) {
        this.$store.commit("users/setPistbar", v);
      },
    },
  },
  watch: {
    clock: function (e) {
      if (e) {
        let fn = () => {
          let current = new Date();
          this.timestr = current.toLocaleTimeString();
        };
        fn();
        this.invp = setInterval(fn, 1000);
      } else clearInterval(this.invp);
      console.log(this.invp);
    },
  },
  created() {
    //if (process.browser) {
    if (localStorage.getItem("DarkMode")) {
      const cookieValue = localStorage.getItem("DarkMode") === "true";
      this.darkmode = cookieValue;
    }
    this.handleDarkMode();
    //}
  },
  methods: {
    toggleDarkMode() {
      this.darkmode = !this.darkmode;
      this.handleDarkMode();
    },
    handleDarkMode() {
      //if (process.browser) {
      this.$vuetify.theme.dark = this.darkmode;
      localStorage.setItem("DarkMode", this.darkmode);
      //}
    },
  },
};
</script>
