var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[(this.value)?_c('v-input',{attrs:{"label":"Stops","small":""}}):_vm._e(),_vm._l((Object.keys(_vm.divisions)),function(dd){return _c('span',{key:dd,staticClass:"mr-1"},[(_vm.value)?_c('span',[_vm._v(" "+_vm._s(_vm.divName(dd))+" ")]):_vm._e(),_vm._l((_vm.stops.filter((e) => _vm.isdiv(e, dd))),function(stop){return _c('v-tooltip',{key:stop,attrs:{"open-delay":"1000","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({directives:[{name:"show",rawName:"v-show",value:(!_vm.compact || _vm.open || _vm.ison(stop)),expression:"!compact || open || ison(stop)"}],class:_vm.outlined
              ? _vm.isReed(stop)
                ? 'red--text'
                : ''
              : _vm.ison(stop)
              ? 'primary highlight'
              : ' ' + (_vm.isReed(stop) ? ' red--text' : ''),style:(_vm.compact && _vm.ison(stop) ? 'cursor: no-drop' : ''),attrs:{"outlined":_vm.outlined},on:{"click":function($event){_vm.disabled ? null : _vm.toggle(stop)}}},on),[(!_vm.inverted && stop < 0)?_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-minus-circle-outline")]):_vm._e(),(_vm.inverted && stop < 0)?_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-plus-circle-outline")]):_vm._e(),_vm._v(" "+_vm._s(_vm.stopName(stop))+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.stopLong(stop)))])])})],2)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.compact),expression:"compact"}],attrs:{"icon":""},on:{"click":function($event){_vm.open = !_vm.open}}},[(!_vm.open)?_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}]},[_vm._v("mdi-plus-box-outline")]):_vm._e(),(_vm.open)?_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}]},[_vm._v("mdi-unfold-less-vertical")]):_vm._e()],1)]}}])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }