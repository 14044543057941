<style scoped>
.blink {
  animation: blinking 1.5s steps(2, start) infinite;
  -webkit-animationxxx: blinking 2s steps(2, start) infinite;
}

@keyframes blinking {
  to {
    visibility: hidden;
  }
}
</style>
<template>
  <span>
    <v-row justify="end">
      <v-col xs4 sm2 class="text-left">
        <v-btn @click="doButton({ cmd: 'piston.run', press: 'P-' })">P-</v-btn>
        <v-btn @click="doButton({ cmd: 'piston.run', press: 'P+' })">P+</v-btn>
      </v-col>

      <v-col xs4 sm2 class="text-center"
        ><v-btn
          :color="this.state.recording != 'off' ? 'red blink' : ''"
          @click="doButton({ cmd: 'piston.run', hold: 'S' })"
          >S</v-btn
        >
        <v-btn @click="doButton({ cmd: 'piston.run', press: 'C' })"
          >C</v-btn
        ></v-col
      >

      <v-col xs4 sm2 class="text-right">
        <v-btn @click="doButton({ cmd: 'sequence.godigit', args: '-10' })"
          >-10</v-btn
        >
        <v-btn @click="doButton({ cmd: 'sequence.godigit', args: '+10' })"
          >+10</v-btn
        >
        <v-btn @click="doButton({ cmd: 'piston.run', press: '-' })">-</v-btn>
      </v-col>
    </v-row>

    <v-row class="ma-0">
      <v-col xs12>
        <v-card class="pa-2" @click="$router.replace('library')">
          <v-card-title v-if="state.comment" class="text-h5"
            ><v-icon class="mr-2">mdi-comment-outline</v-icon
            >{{ state.comment }}</v-card-title
          >
          <v-card-title v-if="!state.comment">{{ sequence.name }}</v-card-title>

          <v-card-subtitle v-if="!state.comment">{{
            sequence.composer
          }}</v-card-subtitle>
          <v-card-subtitle v-if="state.comment">{{
            sequence.name
          }}</v-card-subtitle>

          <div @click="$router.replace('sequence')"
            class="text-h2 font-weight-black mt-0"
            style="text-align: right"
            type="number"
          >
            &nbsp;
            <v-icon
              v-if="
                sequence &&
                sequence.steps &&
                sequence.steps.length == curposition + 1
              "
              >mdi-arrow-collapse-right</v-icon
            >
            <span name="blink" class="blink" v-if="tens">{{ tens }}</span
            ><span v-else>{{ curlabel }}</span>
          </div>
        </v-card></v-col
      ></v-row
    >

    <v-row class="ma-0">
      <v-col class="d-none d-md-flex"
        ><actionformatter large :item="curstep"></actionformatter
      ></v-col>

      <v-col xs12>
        <v-btn
          class="justify-right"
          x-large
          block
          :color="recordingColor"
          @click="doButton({ cmd: 'piston.run', press: '+' })"
          >+</v-btn
        ></v-col
      >
    </v-row>
  </span>
</template>

<script>
// import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      label: "",
    };
  },
  computed: {
    ...mapGetters("sequence", [
      "sequence",
      "state",
      "curlabel",
      "curstep",
      "curposition",
    ]),

    recordingColor() {
      if (this.state.recording == "on") return "red";
      else if (this.state.recording == "oneshot") return "pink";
      else return "green";
    },
    recording() {
      return this.state.recording;
    },
    tens() {
      return this.state.tens;
    },
  },
  methods: {
    doButton(v) {
      this.$socket.emit("action", v);
    },
  },
  mounted() {},
};
</script>
