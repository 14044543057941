// https://a2i2.deakin.edu.au/2020/04/23/real-time-data-transfer-using-vue-and-socket-io-part-1-of-3/
// https://github.com/vuejs/vuex/tree/dev/examples/shopping-cart

import Vue from "vue";
import Vuex from "vuex";

import stops from "./modules/stops";
import specification from "./modules/specification";
import library from "./modules/library";
import sequence from "./modules/sequence";
import combination from "./modules/combination";
import users from "./modules/users";

import createsocketIOPlugin from "./socketioplugin";
import socket from "../socket";

// the Vuex store system is used to share state between Vue component and the server via websockets
// the socketio server sends initial config on (re-)connect

Vue.use(Vuex);
const socketIOPlugin = createsocketIOPlugin(socket);
const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    stops,
    specification,
    library,
    users,
    sequence,
    combination,
  },
  actions: {
    sendMessage(context, event, ...payload) {
      this.$socket.emit(event, ...payload);
    },
  },
  strict: debug,
  plugins: [socketIOPlugin],
});
