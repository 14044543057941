<template>
  <v-card class="mx-auto" @keydown.enter="apply()">
    <v-card-title
      >Configuration
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">label</th>
              <th class="text-left">value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Backend</td>
              <td>
                <v-text-field v-model="backend" />
              </td>
            </tr>
            <tr>
              <td>Tooltips on stop names</td>
              <td>
                <v-switch v-model="tooltips" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-card-actions>
        <v-btn @click="apply()">Apply</v-btn>
      </v-card-actions>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">board</th>
              <th class="text-left">description</th>
              <th class="text-left">id</th>
              <th class="text-left">last config</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="item in devices" :key="item.id">
              <td>{{ item.board }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.id }}</td>
              <td>{{ item.timestamp }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import App from "../App.vue";

export default {
  label: "Configure",

  data() {
    return {
      backend: localStorage.getItem("backEnd"),
      tooltips: localStorage.getItem("toolTips"),
    };
  },
  computed: {
    ...mapGetters("specification", ["devices"]),
  },
  methods: {
    apply() {
      localStorage.setItem("backEnd", this.backend);
      localStorage.setItem("toolTips", this.tooltips);
    },
  },
};
</script>
