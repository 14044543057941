// initial state
const state = () => ({
  items: [],
});

// getters
const getters = {
  // eslint-disable-next-line no-unused-vars
  stopStates: (state, getters, rootState) => {
    return state.items;
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  setStopState(state, { id, mode }) {
    if (mode == 2) state.items[id] = !state.items[id];
    else state.items[id] = mode == 0 ? false : true;
  },

  state(state, items) {
    state.items = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
