import router from "../../router";

// initial state
const state = () => ({
  combination: [],
  autonav: "",
});

// getters
const getters = {
  curposition: (state) => {
    if (
      state.combination &&
      state.combination.state &&
      state.combination.state.positions.Gen
    )
      return state.combination.state.positions.Gen;
    else return -1;
  },
  curpositions: (state) => {
    if (
      state.combination &&
      state.combination.state &&
      state.combination.state.positions
    )
      return state.combination.state.positions;
    else return [];
  },
  curcomb: (state) => {
    if (
      state.combination &&
      state.combination.combinations &&
      state.combination.state.positions.Gen >= 0 &&
      state.combination.state.positions.Gen <=
        state.combination.combinations.length
    )
      return state.combination.combinations[
        state.combination.state.positions.Gen
      ];
    return null;
  },
  curlabel: (state) => {
    var lbl = "";
    if (
      state.combination &&
      state.combination.state &&
      state.combination.state.labels
    ) {
      if (state.combination.state.labels.Gen)
        lbl = state.combination.state.labels.Gen;
      else lbl = "";
      for (const [key, value] of Object.entries(
        state.combination.state.labels
      )) {
        if (key != "Gen") lbl += " (" + key + ": " + value + ")";
      }
    }
    return lbl;
  },
  combination: (state) => {
    return state.combination ? state.combination : [];
  },
  state: (state) => {
    return state.combination && state.combination.state
      ? state.combination.state
      : {
          recording: "off",
          canredo: false,
          canundo: false,
        };
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  state(state, value) {
    state.combination = value;
    if (state.combination && state.combination.combinations) {
      state.combination.combinations.forEach((element, index) => {
        element.id = index;
        if (!element.label) element.label = "";
      });
      // if (state.combination.state.position < 0) state.combination.state.position = 0;
      if (
        state.combination.state.position >=
        state.combination.combinations.length
      )
        state.combination.state.position =
          state.combination.combinations.length - 1;

      if (
        state.autonav != "" &&
        router.currentRoute.fullPath != state.autonav
      ) {
        router.push(state.autonav);
      }

      state.autonav = "";
    }
  },
  autonav(state, value) {
    state.autonav = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
