// socket.js
import io from "socket.io-client";
let urlParams = new URLSearchParams(window.location.search);

if (urlParams.has("backEnd")) {
      console.log("Backend set to " + urlParams.get("backEnd"));
      localStorage.setItem("backEnd", urlParams.get("backEnd"));
}
 
var backend = localStorage.getItem("backEnd");

if (backend == null) {
  backend = "localhost:2709";
}

console.log("Using backend " + backend);

const socket = io(backend);
export default socket;
