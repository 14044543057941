<template>
  <span>
    <v-dialog v-model="dialog" @keydown.enter="saveKV()">
      <v-card>
        <v-card-text>
          <v-text-field v-model="edittype" label="type"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Cancel </v-btn>
          <v-btn text @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-chip
      @click="edit"
      :small="!large"
      :color="
        actionDecorator().color +
        (this.$vuetify.theme.dark ? ' darken-4' : ' lighten-4')
      "
      ><v-icon small class="mr-2" v-if="actionDecorator().icon">{{ actionDecorator().icon }}</v-icon>
      {{ actionDecorator().label }}</v-chip
    >
  </span>
</template>
<script>
// import { mapGetters } from "vuex";

export default {
  data() {
    return {
      state: this.value,
      edittype: "",
      dialog: false
    };
  },

  methods: {
    edit() {
      if (!this.value) return;
      this.edittype = this.state.action
      this.dialog = true

    },
    save() {
      console.log(this.edittype)
      this.$set(this.state, "action", this.edittype)
      this.$emit("input", this.state);
      this.dialog = false
    },
    actionDecorator() {
      var decs = [
        { regex: "stop.*", color: "", icon: "" },
        { regex: "sequence.comment", color: "green", icon: "mdi-comment-outline", nlabel: "comment" },
        { regex: "piston.*", color: "red", icon: "mdi-code-greater-than" },
        { regex: "library.*", color: "green", icon: "mdi-playlist-play" },
        { regex: "bluetooth.*", color: "blue", icon: "mdi-bluetooth" },
        { regex: ".*", color: "red", icon: "mdi-code-greater-than" },
      ];

      var action = null;

      if (this.action) action = this.action;
      else if (this.state) action = this.state.action;
      else if (this.item) action = this.item.action;
      else {
        decs[0].label = "none";
        return decs[0];
      }

      var ret = null;

      //console.log('to match = ' + action)

      decs.some((m) => {
        if (action.match(m.regex)) {

          m.label = m.nlabel ? m.nlabel : action.replace(".", " ");
          ret = m;
          return true;
        }
        return false;
      });

      if (ret) return ret;


      decs[0].label = item.action;
      return decs[0];
    },
  },
  props: {
    item: { type: Object, default: null },
    large: { type: Boolean, default: false },
    action: { type: String, default: null },
    value: { type:  Object, default: null }
  },
};
</script>
