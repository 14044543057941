<template>
  <v-app>
    <navbar></navbar>
    <v-main
      ><v-container fluid><router-view /></v-container
    ></v-main>

    <pistonbar></pistonbar>
  </v-app>
</template>
<style>
html {
  user-select: none;
  overflow-y: auto !important;
}
button {
  touch-action: manipulation;
}
</style>

<script>
import Navbar from "@/components/NavBar";
import Pistonbar from "@/components/PistonBar";

export default {
  components: { Navbar, Pistonbar },
};
</script>
