<template>
  <v-card class="mx-auto">
    <v-dialog v-model="newLibraryDialog">
      <v-card>
        <v-card-title>
          <span class="text-h5">New library</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="editedMeta.name"
                  label="Library Name"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeLibrary"> Cancel </v-btn>
          <v-btn text @click="saveLibrary"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="moveItemDialog">
      <v-card>
        <v-card-title>Move / rename {{ editedMeta.item.name }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="editedMeta.item.path"
            label="Filename"
          ></v-text-field>
          <v-treeview
            :active="treeSelected"
            :items="treeItems"
            :open="treeOpen"
            activatable
            :multiple-active="false"
            @update:active="setActive"
          >
            <template v-slot:prepend="{ item, open }">
              <v-icon>
                {{ open ? "mdi-folder-open" : "mdi-folder" }}
              </v-icon>
            </template>
          </v-treeview></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="moveItemDialog = false"> Cancel </v-btn>
          <v-btn text @click="doMoveItem(false)"> Move </v-btn>
          <v-btn text @click="doMoveItem(true)"> Copy </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >

    <v-dialog v-model="newItemDialog">
      <v-card>
        <v-card-title>
          <span class="text-h5">New Sequence</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="editedMeta.composer"
                  label="Composer"
                ></v-text-field>
                <v-text-field
                  v-model="editedMeta.name"
                  label="Name"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeItem"> Cancel </v-btn>
          <v-btn text @click="saveItem"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-title
      >Library - {{ library.library }}
      <v-spacer></v-spacer>

      <v-text-field
        v-show="viewsearch"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>

      <v-btn icon>
        <v-icon @click="viewsearch = !viewsearch">mdi-magnify</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon v-if="showevents" @click="showevents = !showevents"
          >mdi-filter-check-outline</v-icon
        >
        <v-icon v-if="!showevents" @click="showevents = !showevents"
          >mdi-filter-cog-outline</v-icon
        >
      </v-btn>

      <v-btn icon>
        <v-icon @click="toggleEdit">{{
          editmode ? "mdi-lock" : "mdi-pencil"
        }}</v-icon>
      </v-btn>
    </v-card-title>

    <v-data-table
      fixed-header
      :headers="headers"
      :items="filteredlibrary"
      :search="search"
      :items-per-page="-1"
      hide-default-footer
      :footer-props="{ disablePagination: true, disableItemsPerPage: true }"
      :dense="$vuetify.breakpoint.mdAndUp"
      mobile-breakpoint="0"
    >
      <template v-slot:item="{ item }">
        <tr
          :class="
            item.id == library.position
              ? 'primary white--text'
              : item.hidden
              ? 'grey--text'
              : ''
          "
        >
          <td @click="selectSequence(item)">
            <v-chip v-if="item.type == 'sequence'" small
              ><v-icon>mdi-playlist-play</v-icon></v-chip
            >
            <v-chip v-if="item.type == 'combination'" small
              ><v-icon>mdi-dots-horizontal</v-icon></v-chip
            >
            <v-icon v-if="item.type == 'directory'" color="primary"
              >mdi-folder</v-icon
            >
            <v-icon v-if="item.type == 'file'">mdi-file-remove-outline</v-icon>
          </td>
          <td @click="selectSequence(item)">
            <span v-if="item.type == 'sequence'">{{ item.composer }} -</span>
            {{ item.name }}
            <v-chip v-if="item.event" small class="green highlight">{{
              item.event
            }}</v-chip>
          </td>
          <td align="right" class="d-none d-md-table-cell">{{ item.date }}</td>
          <td align="right" class="d-none d-md-table-cell">
            <span v-if="item.type == 'sequence'"> </span>
          </td>
          <td align="right">
            <v-btn
              icon
              v-if="
                editmode &&
                item.id == filteredlibrary[filteredlibrary.length - 1].id
              "
              @click="newItem(item.id)"
              ><v-icon>mdi-playlist-plus</v-icon></v-btn
            >
            <v-icon v-if="editmode" @click="libraryAction('up', item)"
              >mdi-arrow-up-bold-box-outline</v-icon
            >
            <v-icon v-if="editmode" @click="libraryAction('down', item)"
              >mdi-arrow-down-bold-box-outline</v-icon
            >
            <v-menu v-if="editmode" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="newItem(item.id, 'sequence')">
                  <v-list-item-title
                    ><v-icon>mdi-playlist-plus</v-icon>Add
                    sequence</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="newItem(item.id, 'combination')">
                  <v-list-item-title
                    ><v-icon>mdi-card-plus-outline</v-icon>Add
                    combination</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="newLibrary(item.id)">
                  <v-list-item-title
                    ><v-icon>mdi-folder-plus</v-icon>Add
                    library</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="libraryAction('delete', item)">
                  <v-list-item-title
                    ><v-icon>mdi-delete</v-icon>Delete</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="moveItem(item)">
                  <v-list-item-title
                    ><v-icon>mdi-folder-arrow-right</v-icon>Move /
                    rename</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="libraryActionS('load', item)">
                  <v-list-item-title
                    ><v-icon>mdi-playlist-edit</v-icon>Load</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="libraryAction('hidden', item)">
                  <v-list-item-title
                    ><v-icon>mdi-file-lock</v-icon>Skip</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
// https://github.com/vuetifyjs/vuetify/issues/3993
export default {
  data() {
    return {
      editmode: false,
      showevents: false,
      search: "",
      viewsearch: false,
      newLibraryDialog: false,
      newItemDialog: false,
      moveItemDialog: false,
      editedMeta: {
        item: {},
        path: null,
        type: "unset",
        name: "",
        library: "",
        composer: "",
      },

      headers: [
        { text: "Type", value: "type", width: "1%" },
        { text: "Name", value: "name" },
        {
          text: "Date",
          value: "date",
          align: "right d-none d-md-table-cell",
        },
        {
          text: "Details",
          value: "steps",
          align: "right d-none d-md-table-cell",
        },
        { text: "", value: "edit", sortable: false },
      ],

      treeSelected: [], // ["Evensongs\\2023-08-12"],
      treeOpen: [], //     ["Evensongs"],
      treeItems: [],
    };
  },
  computed: {
    ...mapGetters("library", ["library"]),
    ...mapGetters("specification", ["poweredon"]),
    filteredlibrary() {
      if (this.showevents || !this.library) return this.library.items;
      else return this.library.items.filter((e) => !e.event || e.active);
    },
  },

  methods: {
    toggleEdit() {
      this.editmode = !this.editmode;
    },
    selectSequence(v) {
      if (v.type == "directory") this.libraryAction("cd", v);
      if (v.type == "sequence") {
        this.libraryActionS("loadrun", v);
      }
      if (v.type == "combination") {
        this.libraryActionS("loadrun", v);
      }
    },
    libraryActionS: function (a, v) {
      this.libraryAction(a, v);
      console.log(v.type);
      if (v.type == "sequence")
        this.$store.commit(
          "sequence/autonav",
          this.poweredon ? "/performance" : "/sequence"
        );
      if (v.type == "combination")
        this.$store.commit("combination/autonav", "/combination");
    },
    libraryAction: function (a, v) {
      this.$socket.emit("action", {
        cmd: "library." + a,
        path: v.path,
        args: v,
      });
    },
    newLibrary(item) {
      this.editedMeta.item = Object.assign({}, item);
      // keep same address to support v-model. Must be a more elegant way ??
      this.newLibraryDialog = true;
    },
    newItem(item, type) {
      this.editedMeta.item = Object.assign({}, item);
      this.editedMeta.type = type;
      // keep same address to support v-model. Must be a more elegant way ??
      this.newItemDialog = true;
    },
    findAndOpenItem(tree) {
      var found = false;
      tree.forEach((e) => {
        if (e.id == this.library.path) {
          this.treeOpen.push(e.id);
          found = true;
        }
        if (e.children && this.findAndOpenItem(e.children)) {
          this.treeOpen.push(e.id);
          found = true;
        }
      });
      return found;
    },
    moveItem(item) {
      this.editedMeta.item = Object.assign({}, item);
      this.editedMeta.path = null;
      this.treeSelected = [this.library.path];
      this.treeOpen = [];
      this.findAndOpenItem(this.treeItems);

      console.log(this.treeSelected);
      this.moveItemDialog = true;
    },
    setActive(value) {
      this.editedMeta.path = value[0];
    },
    doMoveItem(copy) {
      console.log(
        "doMoveItem copy = " +
          copy +
          " src = " +
          this.editedMeta.item.path +
          " dest = " +
          this.editedMeta.path
      );
      this.libraryAction("movecopy", {
        src: this.editedMeta.item.path,
        copy: copy,
        dst: this.editedMeta.path,
      });
      this.moveItemDialog = false;
    },
    saveLibrary() {
      this.libraryAction("addlibrary", this.editedMeta);
      this.newLibraryDialog = false;
    },
    closeLibrary() {
      this.newLibraryDialog = false;
    },
    saveItem() {
      this.libraryAction("additem", this.editedMeta);
      this.newItemDialog = false;
    },
    closeItem() {
      this.newItemDialog = false;
    },
  },
  mounted() {
    let self = this;

    window.addEventListener("keyup", function (event) {
      if (event.key === "F2") {
        self.toggleEdit();
      }
    });

    this.$socket.emit("action", "library.gettree", (ret) => {
      this.treeItems = ret;
    });
  },
};
</script>
