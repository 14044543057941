<template>
  <v-footer app v-if="spistbar">
    <v-container>
      <v-layout row wrap justify-space-around>
        <v-flex
          class="pa-1"
          xs4
          sm2
          v-for="button in buttons"
          :key="button.name"
        >
          <v-btn
            block
            :color="getColor(button)"
            @click="doPiston(button.action)"
            v-longclick="() => doPiston(button.longaction)"
            ><v-icon v-if="button.icon">{{ button.icon }}</v-icon
            ><span v-else>{{ button.name }}</span></v-btn
          >
        </v-flex>
      </v-layout>
    </v-container>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    buttons: [
      {
        name: "P-",
        color: "",
        action: { cmd: "piston.run", press: "P-" },
        longaction: { cmd: "piston.run", hold: "1" },
      },
      {
        name: "P+",
        action: { cmd: "piston.run", press: "P+" },
        longaction: { cmd: "piston.run", hold: "2" },
      },
      {
        name: "-",
        action: { cmd: "piston.run", press: "-" },
        longaction: { cmd: "stop.off", args: "all" },
      },
      {
        icon: "mdi-arrow-collapse-left",
        action: { cmd: "sequence.run", position: 0 },
        longaction: { cmd: "piston.run", press: "6" },
      },
      {
        icon: "mdi-animation-play",
        color: "primary",
        action: { router: "performance" },
      },
      {
        name: "+",
        color: "recording",
        action: { cmd: "piston.run", press: "+" },
        longaction: "stop.restore",
      },
    ],
    pistbar: true,
  }),
  methods: {
    doPiston(v) {
      if (v.router) {
        if (this.$router.name !== v.router) this.$router.replace(v.router);
      } else this.$socket.emit("action", v);
    },
    getColor(b) {
      if (b.color == "recording") {
        if (this.state.recording == "on") return "red";
        else if (this.state.recording == "oneshot") return "pink";
        else return "green";
      } else return b.color;
    },
  },
  computed: {
    ...mapGetters("sequence", ["sequence", "state"]),
    spistbar: {
      get() {
        return (
          this.$store.state.users.showPistbar &&
          this.$route.name !== "performance"
        );
      },
      set(v) {
        this.$store.commit("users/setPistbar", v);
      },
    },
  },
};
</script>
