<style scoped>
.blink {
  animation: blinking 1.5s steps(2, start) infinite;
  -webkit-animationxxx: blinking 2s steps(2, start) infinite;
}

@keyframes blinking {
  to {
    visibility: hidden;
  }
}
</style>
<template>
  <v-card>
    <v-card-title>Crescendo control</v-card-title>
    <v-card-text>
      <v-slider min="0" max="100" v-model="percent" @input="updateCrescendo">
        <template v-slot:prepend>
          <v-btn small primary fab @click="decrement"
            ><v-icon>mdi-minus</v-icon></v-btn
          >
        </template>

        <template v-slot:append>
          <v-btn small primary fab @click="increment"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </template>
      </v-slider>
    </v-card-text>
  </v-card>
</template>

<script>
// import axios from "axios";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("sequence", ["state"]),

    percent: {
      get() {
        return this.state.percent;
      },
      set(v) {
        this.updateCrescendo(v);
      },
    },
  },

  methods: {
    decrement() {
      this.$socket.emit("action", { cmd: "sequence.prev" });
    },
    increment() {
      this.$socket.emit("action", { cmd: "sequence.next" });
    },
    updateCrescendo(v) {
      console.log("crescendo " + v + " vs " + this.state.percent);
      if (v != this.state.percent)
        this.$socket.emit("action", {
          cmd: "sequence.go",
          percent: v,
        });
    },
  },
  mounted() {},
};
</script>
