<template>
  <div>
    <v-dialog
      v-model="logindialog"
      width="unset"
      @keydown.enter="login()"
      @keyup.esc="logindialog = false"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Enter pincode</span>
        </v-card-title>

        <v-card-text>
          <v-text-field
            label="User"
            disabled
            :value="loginuser.user"
            prepend-icon="mdi-account circle"
          >
          </v-text-field>

          <v-text-field
            v-model="pincode"
            ref="password"
            type="password"
            label="Pincode"
            prepend-icon="mdi-lock"
            autofocus
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="logindialog = false"> Cancel </v-btn>
          <v-btn text @click="login()"> Login </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="alert" width="60%">
      <v-card>
        <v-alert type="error" v-model="alert" dismissible width="unset">{{
          message
        }}</v-alert>
      </v-card>
    </v-dialog>

    <v-card class="mx-auto">
      <v-card-title
        >User management
        <v-spacer></v-spacer>

        <v-text-field
          v-show="viewsearch"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-btn icon>
          <v-icon @click="viewsearch = !viewsearch">mdi-magnify</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        fixed-header
        :headers="headers"
        :items="users"
        :search="search"
        hide-default-footer
        :dense="$vuetify.breakpoint.mdAndUp"
        mobile-breakpoint="0"
        :footer-props="{ disablePagination: true, disableItemsPerPage: true }"
      >
        <template v-slot:item="{ item }">
          <tr @click="loginDialog(item)">
            <td><v-icon v-if="item.active">mdi-play</v-icon>{{ item.user }}</td>

            <td>{{ item.name }}</td>

            <td align="right" class="d-none d-md-table-cell">
              {{ item.date }}
            </td>
            <td align="right" class="d-none d-md-table-cell">
              {{ item.items }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// https://github.com/vuetifyjs/vuetify/issues/3993
export default {
  data() {
    return {
      search: "",
      loginuser: {},
      pincode: "",
      message: "",
      viewsearch: false,
      logindialog: false,
      alert: false,
      headers: [
        { text: "User", value: "user" },
        { text: "Fullname", value: "name" },
        {
          text: "Last update",
          value: "date",
          align: "right d-none d-md-table-cell",
        },
        {
          text: "# items",
          value: "items",
          align: "right d-none d-md-table-cell",
          width: "1%",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("users", ["users"]),
  },

  methods: {
    loginDialog(item) {
      this.loginuser = item;
      this.logindialog = true;
    },
    doAlert(msg) {
      if (msg["error"]) {
        this.$store.commit("library/autonav", "");
        this.message = msg["error"];
        this.alert = true;
      }
    },
    login() {
      this.loginuser["pincode"] = this.pincode;

      this.$store.commit("library/autonav", "/performance");
      this.$socket.emit(
        "action",
        { cmd: "users.login", args: this.loginuser },
        (ret) => {
          this.doAlert(ret);
        }
      );

      this.logindialog = false;
    },
  },
  mounted() {
    let self = this;

    window.addEventListener("keyup", function (event) {
      if (event.code === 13) {
        self.login();
      }
    });
  },
};
</script>
