import Vue from "vue";
import VueRouter from "vue-router";
import StopView from "./views/StopView.vue";
import PerformanceView from "./views/PerformanceView.vue";
import GuidedView from "./views/GuidedView.vue";
import CrescendoView from "./views/CrescendoView.vue";
import SequenceView from "./views/SequenceView.vue";
import CombinationView from "./views/CombinationView.vue";
import Configure from "./views/ConfigurationView.vue";
import LibraryView from "./views/LibraryView.vue";
import User from "./views/UserView.vue";
import InfoView from "./views/InfoView.vue";

Vue.use(VueRouter);

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,

  routes: [
    {
      path: "/",
      redirect: { path: "/performance" },
    },
    {
      path: "/performance",
      name: "performance",
      component: PerformanceView,
    },
    {
      path: "/guided",
      name: "guided",
      component: GuidedView,
    },
    {
      path: "/stops",
      name: "stops",
      component: StopView,
    },
    {
      path: "/sequence",
      name: "sequence",
      component: SequenceView,
    },
    {
      path: "/combination",
      name: "combination",
      component: CombinationView,
    },
    {
      path: "/crescendo",
      name: "crescendo",
      component: CrescendoView,
    },

    {
      path: "/library",
      name: "library",
      component: LibraryView,
    },
    {
      path: "/configure",
      name: "configure",
      component: Configure,
    },
    {
      path: "/user",
      name: "user",
      component: User,
    },
    {
      path: "/info",
      name: "info",
      component: InfoView,
    },
  ],
});
