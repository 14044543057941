import Vue from "vue";
import Vuex from "vuex";

import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import socket from "./socket";

import Stoplist from "@/components/Stoplist";
import Pistonlist from "@/components/Pistonlist";
import NameValuelist from "@/components/NameValuelist";
import ActionFormatter from "@/components/ActionFormatter";
import { longClickDirective } from "vue-long-click";

const longClickInstance = longClickDirective({ delay: 1000, interval: 0 });

Vue.directive("longclick", longClickInstance);
Vue.component("stoplist", Stoplist);
Vue.component("pistonlist", Pistonlist);
Vue.component("namevaluelist", NameValuelist);
Vue.component("actionformatter", ActionFormatter);
Vue.use(Vuex);

Vue.config.productionTip = false;

Vue.prototype.$socket = socket;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
