/* eslint-disable no-unused-vars */
// initial state
const state = () => ({
  specification: [],
  devices: [],
});

// getters
const getters = {
  specification: (state, _getters, _rootState) => {
    return state.specification;
  },
  devices: (state, _getters, _rootState) => {
    return state.devices;
  },
  poweredon: (state, _getters, _rootState) => {
    for (var d in state.devices)
    {
      if (state.devices[d].timestamp) return true
    }  
    return false;
  },
  divisions: (state, _getters, _rootState) => {
    return state.specification ? state.specification["divisions"] : [];
  },
  stoplist: (state, _getters, _rootState) => {
    return state.specification ? state.specification["stops"] : [];
  },
  divstoplist: (_state, getters, _rootState) => {
    var divStoplist = [];
    for (const [divid, divspec] of Object.entries(getters.divisions)) {
      divStoplist[divid] = [];
    }
    if (getters.divisions) {
      // eslint-disable-next-line no-undef
      for (const [index, value] of Object.entries(getters.stoplist)) {
        divStoplist[value.div].push({ id: index, ...value });
      }
    }
    return divStoplist;
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  set(state, spec) {
    state.specification = spec;
    //console.log(spec);
  },
  devices(state, spec) {
    state.devices = spec;
    //console.log(spec);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
