<template>
  <v-card class="mx-auto pa-2">
    <div v-html="info" />
  </v-card>
</template>

<script>
// import axios from "axios";

export default {
  data() {
    return {
      info: "wait",
    };
  },
  mounted() {
    this.$socket.emit("asset", "info.html", (ret) => {
      this.info = ret;
    });
  },
};
</script>
