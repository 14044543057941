<template>
  <span>
    <v-input v-if="this.value" label="Stops" small />

    <span v-for="dd in Object.keys(divisions)" :key="dd" class="mr-1">
      <span v-if="value"> {{ divName(dd) }} </span>
      <v-tooltip
        open-delay="1000"
        bottom
        v-for="stop in stops.filter((e) => isdiv(e, dd))"
        :key="stop"
      >
        <template #activator="{ on }">
          <v-chip
            v-show="!compact || open || ison(stop)"
            v-on="on"
            :outlined="outlined"
            @click="disabled ? null : toggle(stop)"
            :class="
              outlined
                ? isReed(stop)
                  ? 'red--text'
                  : ''
                : ison(stop)
                ? 'primary highlight'
                : ' ' + (isReed(stop) ? ' red--text' : '')
            "
            :style="compact && ison(stop) ? 'cursor: no-drop' : ''"
          >
            <v-icon class="mr-1" v-if="!inverted && stop < 0" small
              >mdi-minus-circle-outline</v-icon
            >
            <v-icon class="mr-1" v-if="inverted && stop < 0" small
              >mdi-plus-circle-outline</v-icon
            >
            {{ stopName(stop) }}
          </v-chip>
        </template>
        <span>{{ stopLong(stop) }}</span>
      </v-tooltip> </span
    ><v-hover v-slot:default="{ hover }"
      ><v-btn icon v-show="compact" @click="open = !open"
        ><v-icon v-show="hover" v-if="!open">mdi-plus-box-outline</v-icon>
        <v-icon v-show="hover" v-if="open">mdi-unfold-less-vertical</v-icon>
      </v-btn></v-hover
    ></span
  >
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      stopstate: this.value,
      open: false,
    };
  },
  props: {
    stops: { type: Array, required: true }, // all the stop to show
    inverted: { type: Boolean, default: false }, // reverse the delta logic
    outlined: { type: Boolean, default: false }, // show white border instead of fill
    hidedivs: { type: Boolean, default: false }, // do not show division names for non-unique stop names
    compact: { type: Boolean, default: false }, // show only the stops that are on
    disabled: { type: Boolean, default: false }, // prevent toggle emits
    value: { type: Array, default: null }, // the actual stop values. if null, the store will be used
  },
  computed: {
    ...mapGetters("stops", ["stopStates"]),
    ...mapGetters("specification", ["divisions", "stoplist", "divstoplist"]),

    divcnts() {
      let divcnts = [];
      for (const [k, v] of Object.entries(this.divisions)) {
        divcnts[k] = { name: v.short, minuscnt: 0, pluscnt: 0 };
      }

      this.stops.forEach((stop) => {
        if (stop < 0) divcnts[this.stoplist[-stop].div].minuscnt++;
        else divcnts[this.stoplist[stop].div].pluscnt++;
      });
      return divcnts;
    },
  },
  methods: {
    stopName: function (v) {
      if (!this.stoplist) return "?";
      if (v < 0) v = -v;
      let si = this.stoplist[v];
      if (si.ncount < 2 || this.hidedivs) return si.short;
      else return this.divName(si.div) + " " + si.short;
    },
    stopLong: function (v) {
      if (!this.stoplist) return "?";
      if (v < 0) v = -v;
      let si = this.stoplist[v];
      return this.divLongName(si.div) + " - " + si.long;
    },
    isReed: function (v) {
      if (v < 0) v = -v;
      return this.stoplist ? this.stoplist[v].reed : false;
    },
    divName: function (v) {
      return this.divisions ? this.divisions[v].short : "?";
    },
    divLongName: function (v) {
      return this.divisions ? this.divisions[v].long : "?";
    },
    isdiv: function (v, div) {
      if (v < 0) v = -v;
      return this.stoplist ? this.stoplist[v].div == div : false;
    },
    ison: function (v) {
      if (this.value) {
        // issue to fix: v is str, but value contains array of ints
        return this.stopstate.includes(parseInt(v));
      }
      if (v < 0 || !this.stopStates) return false;
      return this.stopStates[v];
    },
    toggle: function (v) {
      if (this.disabled) return;

      if (this.value) {
        // if in edit mode
        v = parseInt(v);
        let pos = this.stopstate.indexOf(v);
        if (pos < 0) this.stopstate.push(v);
        else this.stopstate.splice(pos, 1);
        this.$emit("input", this.stopstate);
      } else {
        this.$socket.emit("action", { cmd: "stop.toggle", id: v });
      }
    },
  },
};
</script>
