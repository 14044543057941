// https://lukashermann.dev/writing/socketio-with-vue-and-vuex/

export default function createsocketIOPlugin(socket) {
  return (store) => {
    store.$socket = socket;
    socket.onAny((eventName, args) => {
      if (eventName in store._mutations) {
        // prevent error message
        console.log("received " + eventName);
        console.log(args);
        store.commit(eventName, args);
      } else {
        console.log("ignored " + eventName);
      }
    });
  };
}
