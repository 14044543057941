<template>
  <span>
    <v-dialog v-model="kvDialog" @keydown.enter="saveKV()">
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit parameters</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="editKey" label="key"></v-text-field>
                <v-text-field v-model="editValue" label="value"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="editKeyOrig" text @click="deleteKV"> Delete </v-btn>
          <v-btn text @click="closeKV"> Cancel </v-btn>
          <v-btn text @click="saveKV"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <span v-for="k in keys" :key="k">
      <v-chip v-if="value" label small @click="editKV(k)"> {{ k }} </v-chip>
      <v-chip v-else label small> {{ k }} </v-chip>
      {{ nvstate ? nvstate[k] : nvs[k] }}
    </span>
    <v-btn icon v-if="value" @click="addKV()"
      ><v-icon>mdi-plus-box-outline</v-icon>
    </v-btn>
  </span>
</template>
<script>
// import { mapGetters } from "vuex";

export default {
  data() {
    return {
      nvstate: this.value,
      kvDialog: false,
      editKeyOrig: "",
      editKey: "",
      editValue: "",
    };
  },
  methods: {
    editKV(k) {
      console.log("editKV");

      this.editKeyOrig = k;
      this.editKey = k;
      this.editValue = this.nvstate[k];
      console.log(this);
      this.kvDialog = true;
    },
    addKV() {
      console.log("editKV");

      Object.assign(this.editKeyOrig, null);
      this.editKey = "key";
      this.editValue = "";
      this.kvDialog = true;
    },

    closeKV() {
      this.kvDialog = false;
    },
    saveKV() {
      // here we have to use $set and $delete in vue2 because until vue3, changes in objects are not detected

      if (this.editKeyOrig != this.editKey) {
        this.$delete(this.nvstate, this.editKeyOrig);
      }
      this.$set(this.nvstate, this.editKey, this.editValue);
      this.$emit("input", this.nvstate);
      this.closeKV();
    },
    deleteKV() {
      this.$delete(this.nvstate, this.editKeyOrig);
      this.$emit("input", this.nvstate);
      this.closeKV();
    },
  },
  computed: {
    keys() {
      var k = this.nvstate
        ? Array.from(Object.keys(this.nvstate))
        : Array.from(Object.keys(this.nvs));

      k.sort(function (a, b) {
        const order = ["division", "id", "press", "hold", "if", "cmd", "action", "args"];
        var aix = order.indexOf(a);
        var bix = order.indexOf(b);
        if (aix < 0) aix = 99;
        if (bix < 0) bix = 99;

        return aix - bix;
      });

      return k;
    },
    values() {
      return this.nvstate
        ? Array.from(this.nvstate.value())
        : Array.from(this.nvs.values());
    },
  },
  props: {
    value: { type: Object, default: null },
    nvs: { type: Object, default: null },
  },
};
</script>
