<template>
  <v-card class="mx-auto">
    <v-card-title>
      <!-- <v-app-bar-nav-icon /> -->
      <v-toolbar-title @click="$router.replace('sequence')">
        {{ sequence.composer }} - {{ sequence.name }} - {{ curlabel }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn icon @click="compact = !compact"
        ><v-icon v-if="compact">mdi-unfold-more-vertical</v-icon
        ><v-icon v-if="!compact">mdi-unfold-less-vertical</v-icon></v-btn
      >
    </v-card-title>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" width="1%">Division</th>
            <th class="text-left">Stops</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(div, index) in divisions" :key="index">
            <td>{{ div.long }}</td>
            <td>
              <stoplist
                :compact="compact"
                :stops="divstoplist[index].map((e) => e.id)"
                hidedivs
              ></stoplist>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
// import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      search: "",
      selectedStep: 1,
      compact: false,
    };
  },
  computed: {
    ...mapGetters("sequence", ["sequence", "curlabel"]),
    ...mapGetters("specification", ["divisions", "divstoplist"]),
  },
  mounted() {},
};
</script>
