// import { map } from "core-js/core/array";

// initial state
const state = () => ({
  users: [],
  active: null,
  showPistbar: localStorage.getItem("showfooter"),
});

// getters
const getters = {
  users: (state) => {
    return state.users;
  },
  active: (state) => {
    return state.active;
  }
};

// actions
const actions = {};

// mutations
const mutations = {
  state(state, users) {
    state.users = users.users;
    state.active = users.active;
  },
  setPistbar(state, flag) {
    localStorage.setItem("showfooter", flag);
    state.showPistbar = flag;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
